
import { mapGetters } from 'vuex'
import PDEMessageBox from '@/components/pde/PDEMessageBox.vue'
import PDESvg from '@/components/pde/PDESvg.vue'

export default {
  name: 'TravelErrorMessageBox',

  components: {
    PDEMessageBox,
    PDESvg,
  },

  props: {
    displayModal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('account', ['division']),
    travelPhone: function () {
      return this.$t(`account.${this.$featureFlags.travelPhoneNumber10399 ? 'travelNew' : 'travel'}.${this.division}.travelPhone`)
    },
    travelPhoneText: function () { return this.$t(`account.${this.$featureFlags.travelPhoneNumber10399 ? 'travelNew' : 'travel'}.${this.division}.travelPhoneText`) },
  },
}
